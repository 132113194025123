import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ServiceCard from "../components/service-card";
import {graphql, Link, useStaticQuery} from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../styles/swiper.css";
import {StaticImage} from "gatsby-plugin-image";

export default function IndexPageOld() {

    const data = useStaticQuery(graphql`
        query GetServicesOld {
          allServicesJson {
            nodes {
              id
              title
              services {
                description
                link
              }
            }
          }
        }
  `)

    const allServices = data.allServicesJson.nodes;

    return (
        <Layout>
            <Seo title="Home Page"/>
            <div className='mt-20 lg:h-96 md:h-72 h-48'>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper">
                    <SwiperSlide>
                        <StaticImage
                            src='../images/ivana-la-61jg6zviI7I-unsplash.jpg'
                            alt='a corgi sitting on a bed with red paper hearts all over it. it looks unamused'
                            placeholder='dominantColor'
                            // width={300}
                            // height={300}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <StaticImage
                            src='../images/smashburger.jpg'
                            alt='a corgi sitting on a bed with red paper hearts all over it. it looks unamused'
                            placeholder='dominantColor'
                            // width={300}
                            // height={300}
                        />
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className='mt-20'>
                <h1 className='text-3xl text-center font-light'>Υπηρεσίες</h1>
                <div className='flex flex-wrap '>
                    {allServices.map(service => (
                        <ServiceCard key={service.id} headerTitle={service.title}>
                            {service.services.map(service => (
                                <div className='pb-2' key={service.id + service.description}>
                                    <Link to={service.link}>{service.description}</Link>
                                </div>
                            ))}
                        </ServiceCard>

                    ))}
                </div>
            </div>
            <div className='mt-20'>
                <h1 className='text-4xl text-center font-light'>Ρωτήστε μας</h1>
                <h1 className='text-2xl text-center font-light'>και θα σας απαντήσουμε άμεσα σε οποιαδήποτε απορία
                    σας.</h1>
            </div>
            <div className='mt-20'>
                <div className='p-8 h-96 bg-indigo-200'>
                    <p className='text-center'>contact form</p>
                </div>
            </div>
        </Layout>
    )
}
